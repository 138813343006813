import { render, staticRenderFns } from "./SignDraw.vue?vue&type=template&id=5d8a38c5&"
import script from "./SignDraw.vue?vue&type=script&lang=js&"
export * from "./SignDraw.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("d:\\workspace\\liveinfo3-front-admin-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d8a38c5')) {
      api.createRecord('5d8a38c5', component.options)
    } else {
      api.reload('5d8a38c5', component.options)
    }
    module.hot.accept("./SignDraw.vue?vue&type=template&id=5d8a38c5&", function () {
      api.rerender('5d8a38c5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/statistics/sign-draw/SignDraw.vue"
export default component.exports