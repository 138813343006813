<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab
          title="가입/탈퇴 회원 수"
          @click="viewType = 'sign-draw'"
        />
        <b-tab
          title="진료과별 가입 회원 수"
          @click="viewType = 'dept-sign'"
        />
        <b-tab
          title="성별 가입 회원 수"
          @click="viewType = 'gender'"
        />
        <b-tab
          title="연령대별 가입 회원 수"
          @click="viewType = 'age-group'"
        />
        <b-tab
          title="본인인증 현황"
          @click="viewType = 'identity-confirm'"
        />
      </b-tabs>
    </b-card>

    <b-card>
      <sign-draw-view v-if="viewType === 'sign-draw'" />
      <dept-sign-view v-if="viewType === 'dept-sign'" />
      <gender-view v-if="viewType === 'gender'" />
      <age-group-view v-if="viewType === 'age-group'" />
      <identity-confirm-view v-if="viewType === 'identity-confirm'" />
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import SignDrawView from './view/SignDrawView.vue'
import DeptSignView from './view/DeptSignView.vue'
import GenderView from './view/GenderView.vue'
import AgeGroupView from './view/AgeGroupView.vue'
import IdentityConfirmView from './view/IdentityConfirmView.vue'

export default {
  components: {
    BTab,
    BTabs,
    SignDrawView,
    DeptSignView,
    GenderView,
    AgeGroupView,
    IdentityConfirmView,
  },

  setup() {
    const viewType = ref("sign-draw")

    return {
      viewType,
    }
  },
}
</script>
