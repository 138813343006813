<template>
  <div>
    <b-row>
      <b-col
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <p class="h5 mt-50 mr-75 ml-50">
          일별/월별
        </p>
        <b-form-radio-group
          v-model="query.isPrefixSum"
          @change="changePrefixSum"
        >
          <b-form-radio :value="false">
            일별 (기간:최대1개월)
          </b-form-radio>
          <b-form-radio :value="true">
            월별 (기간:최대1년)
          </b-form-radio>
        </b-form-radio-group>
        <v-date-picker
          v-model="query.startDate"
          type="month"
          value-type="YYYYMM"
          placeholder="조회기간(Fr)"
          class="el-def"
          @change="changeStartDate"
        />
        <label class="ml-50 mr-50">~</label>
        <v-date-picker
          v-model="query.endDate"
          type="month"
          value-type="YYYYMM"
          placeholder="조회기간(To)"
          class="el-def"
        />
      </b-col>
      <b-col
        md="4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="fetchData()"
        >
          조회
        </b-button>
        <b-button
          variant="outline-dark"
          class="btn-search ml-1"
          @click.prevent="excelDownload()"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          />
          <span>엑셀 다운로드</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-table-simple
          bordered
          responsive
          sticky-header
          style="max-height: 65vh;"
        >
          <colgroup>
            <col style="width: 100px">
            <col style="width: 100px">
            <col style="width: 100px">
          </colgroup>
          <b-thead head-variant="light">
            <b-tr>
              <b-th>
                구분
              </b-th>
              <b-th>
                남성
              </b-th>
              <b-th>
                여성
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="data.length > 0">
            <b-tr
              v-for="(m, idx) in data"
              :key="`tr-${idx}`"
              :class="{ 'table-active': idx === 0 }"
            >
              <b-td>{{ m.date }}</b-td>
              <b-td>{{ m.manCount | $numberFormatter }}</b-td>
              <b-td>{{ m.womanCount | $numberFormatter }}</b-td>
            </b-tr>
          </b-tbody>

          <b-tbody v-else>
            <b-tr>
              <b-td colspan="3">
                <div
                  role="alert"
                  aria-live="polite"
                >
                  <div class="text-center my-2">
                    일치하는 데이터가 없습니다.
                  </div>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from '@axios'
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  setup() {
    const data = ref([])

    onMounted(() => {
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const changePrefixSum = () => {
      if (query.value.isPrefixSum) {
        query.value.startDate = dayjs().add(-2, 'month').format('YYYYMM')
      } else {
        query.value.startDate = dayjs().format('YYYYMM')
      }

      query.value.endDate = dayjs().format('YYYYMM')
    }

    const query = ref({
      startDate: dayjs().format('YYYYMM'),
      endDate: dayjs().format('YYYYMM'),
      isPrefixSum: false,
    })

    const changeStartDate = value => {
      if (!query.value.isPrefixSum) {
        query.value.endDate = value
      }
    }

    const fetchData = () => {
      const frDate = dayjs(query.value.startDate)
      const toDate = dayjs(query.value.endDate)

      if (query.value.isPrefixSum && Math.abs(frDate.diff(toDate, 'month')) > 11) {
        pushToast('warning', '조회 기간은 최대 1년을 넘을 수 없습니다.')
        return
      }
      if (query.value.isPrefixSum === false && Math.abs(frDate.diff(toDate, 'month')) > 0) {
        pushToast('warning', '조회 기간은 최대 1개월을 넘을 수 없습니다.')
        return
      }

      axios.get('/fa/statstrace/gender', {
        params: {
          startDate: frDate.format('YYYY-MM-DD'),
          endDate: toDate.format('YYYY-MM-DD'),
          isPrefixSum: query.value.isPrefixSum,
        },
      })
        .then(rs => {
          data.value = rs.data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      const columns = [
        { header: "구분", key: "date", width: 20 },
        {
          header: "남성", key: "manCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "여성", key: "womanCount", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      worksheet.columns = columns

      // eslint-disable-next-line array-callback-return
      data.value.map(e => {
        const record = {
          date: e.date,
          manCount: e.manCount,
          womanCount: e.womanCount,
        }

        worksheet.addRow(record)
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "성별가입회원수.xlsx")
    }

    return {
      data,
      query,
      fetchData,
      changePrefixSum,
      excelDownload,
      changeStartDate,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}
</style>
