var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-tabs",
            { attrs: { pills: "" } },
            [
              _c("b-tab", {
                attrs: { title: "가입/탈퇴 회원 수" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "sign-draw"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "진료과별 가입 회원 수" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "dept-sign"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "성별 가입 회원 수" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "gender"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "연령대별 가입 회원 수" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "age-group"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "본인인증 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "identity-confirm"
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _vm.viewType === "sign-draw" ? _c("sign-draw-view") : _vm._e(),
          _vm.viewType === "dept-sign" ? _c("dept-sign-view") : _vm._e(),
          _vm.viewType === "gender" ? _c("gender-view") : _vm._e(),
          _vm.viewType === "age-group" ? _c("age-group-view") : _vm._e(),
          _vm.viewType === "identity-confirm"
            ? _c("identity-confirm-view")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }